import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../Shared/Layout';
import PostDetailCard from './PostDetail';
import PostCarousel from './PostCarousel';
import ActionButtons from './ActionButtons';
import { useToast } from '../../toast/ToastContext';
import { deletePostAsync } from '../../redux/slices/appSlice';

const PostDetail = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const { posts } = useSelector((state) => state.app);
  const [currentPost, setCurrentPost] = useState(null);
  const [currentVersionIndex, setCurrentVersionIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const { addToast, clearAllToasts } = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const foundPost = posts.find(post => post.id.toString() === postId);
    if (foundPost) {
      setCurrentPost(foundPost);
    }
  }, [postId, posts]);

  if (!currentPost) {
    return (
      <Layout isScrollable={false}>
        <div className="flex items-center justify-center h-full">
          <p className="text-white text-xl">Post not found</p>
        </div>
      </Layout>
    );
  }

  const versionsCount = Array.isArray(currentPost.versions) ? currentPost.versions.length : 1;

  const handleCopy = () => {
    navigator.clipboard.writeText(Array.isArray(currentPost.versions) ? currentPost.versions[currentVersionIndex] : currentPost.versions);
    addToast('copied', 'info');
  };

  const handleDelete = async () => {
    clearAllToasts();
    try {
      await dispatch(deletePostAsync(currentPost.id)).unwrap();
      addToast('post deleted successfully', 'success');
      setIsVisible(false);
      setTimeout(() => {
        navigate('/');
      }, 500);
    } catch (error) {
      addToast('failed to delete post', 'error');
    }
  };

  const handleSwipe = (direction) => {
    if (versionsCount > 1) {
      if (direction === 'left') {
        setCurrentVersionIndex(prev => (prev + 1) % versionsCount);
      } else if (direction === 'right') {
        setCurrentVersionIndex(prev => (prev - 1 + versionsCount) % versionsCount);
      }
    }
  };

  return (
    <Layout isScrollable={false}>
      <div className="flex flex-col h-full max-w-2xl mx-auto px-2 sm:px-4 md:px-8 pb-20">
        <div className="flex flex-col flex-grow overflow-hidden">
          <AnimatePresence>
            {isVisible && currentPost && (
              <motion.div
                key="post-content"
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.4 }}
                className="flex flex-col flex-grow overflow-hidden"
              >
                <PostDetailCard
                  post={currentPost}
                  currentVersionIndex={currentVersionIndex}
                  versionsCount={versionsCount}
                  onSwipe={handleSwipe}
                />
                {versionsCount > 1 && (
                  <div className="mt-4 hidden sm:block">
                    <PostCarousel
                      onPrevious={() => handleSwipe('right')}
                      onNext={() => handleSwipe('left')}
                      currentVersionIndex={currentVersionIndex}
                      totalVersions={versionsCount}
                    />
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <ActionButtons onCopy={handleCopy} onDelete={handleDelete} />
      </div>
    </Layout>
  );
};

export default PostDetail;
