import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setIsRecording, setShowConfirmButtons, setIsTranscribing, setTranscriptionId } from '../../redux/slices/appSlice';
import useAudioRecording from '../../hooks/useAudioRecording';
import { transcribeAudio } from '../../services/api';
import { useToast } from '../../toast/ToastContext';

const RecordFAB = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const isRecording = useSelector((state) => state.app.isRecording);
  const showConfirmButtons = useSelector((state) => state.app.showConfirmButtons);
  const isTranscribing = useSelector((state) => state.app.isTranscribing);
  const { startRecording, stopRecording } = useAudioRecording();

  const isRecordScreen = location.pathname === '/record';
  const shouldShowFAB = !['/profile', '/generate', '/login'].includes(location.pathname) && !location.pathname.startsWith('/post/');

  const handleClick = async () => {
    if (!isRecordScreen) {
      navigate('/record');
      dispatch(setIsRecording(true));
      await startRecording();
    } else if (isRecording) {
      dispatch(setIsRecording(false));
      const blob = await stopRecording();
      
      if (blob) {
        dispatch(setIsTranscribing(true));
        try {
          const response = await transcribeAudio(blob);
          dispatch(setTranscriptionId(response.data.transcriptionId));
          dispatch(setShowConfirmButtons(true));
        } catch (error) {
          addToast('Failed to transcribe audio', 'error');
        } finally {
          dispatch(setIsTranscribing(false));
        }
      } else {
        addToast('No audio recorded', 'error');
      }
    }
  };

  const isDisabled = showConfirmButtons || isTranscribing;

  if (!shouldShowFAB) {
    return null;
  }

  return (
    <motion.button
      className={`fixed bottom-6 right-6 w-16 h-16 rounded-full flex items-center justify-center focus:outline-none bg-white shadow-lg ${
        isDisabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      whileHover={!isRecording && !isDisabled ? { scale: 1.05 } : {}}
      whileTap={{ scale: 0.95 }}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <motion.div
        className={`w-12 h-12 rounded-full flex items-center justify-center ${isRecording ? 'bg-red-500' : 'bg-blue-100'}`}
        animate={isRecording ? { scale: [1, 1.05, 1] } : {}}
        transition={isRecording ? { repeat: Infinity, duration: 1.5, ease: "easeInOut" } : {}}
      >
        {!isRecording && (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
          </svg>
        )}
      </motion.div>
    </motion.button>
  );
};

export default RecordFAB;