import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RecordScreen from './components/RecordScreen';
import PostFeed from './components/PostFeed';
import ProfilePage from './components/ProfilePage';
import GeneratePost from './components/GeneratePost';
import AuthCallback from './components/AuthCallback/AuthCallback';
import RecordFAB from './components/Shared/RecordFAB';
import authService from './services/authService';
import store from './redux/store';
import PostDetail from './components/PostDetail';
import { ToastProvider } from './toast/ToastContext';
import Login from './components/Login';

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const isRecording = useSelector((state) => state.app.isRecording);

  useEffect(() => {
    const checkAuth = () => {
      const auth = authService.isAuthenticated();
      setIsAuthenticated(auth);
    };
    checkAuth();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const backgroundStyle = {
    background: isRecording
      ? 'linear-gradient(to bottom, #FF6B6B, #FF8E8E)'
      : 'linear-gradient(to bottom, #60A5FA, #3B82F6)'
  };

  return (
    <div className="min-h-screen transition-colors duration-500 overflow-x-hidden" style={backgroundStyle}>
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/" /> : <Login />}
        />
        <Route path="/callback" element={<AuthCallback onLogin={handleLogin} />} />
        <Route
          path="/record"
          element={isAuthenticated ? <RecordScreen /> : <Navigate to="/login" />}
        />
        <Route
          path="/profile"
          element={isAuthenticated ? <ProfilePage /> : <Navigate to="/login" />}
        />
        <Route
          path="/generate"
          element={isAuthenticated ? <GeneratePost /> : <Navigate to="/login" />}
        />
        <Route
          path="/"
          element={isAuthenticated ? <PostFeed /> : <Navigate to="/login" />}
        />
        <Route
          path="/post/:postId"
          element={isAuthenticated ? <PostDetail /> : <Navigate to="/login" />}
        />
      </Routes>
      {isAuthenticated && <RecordFAB />}
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ToastProvider>
        <Router>
          <AppContent />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Router>
      </ToastProvider>
    </Provider>
  );
}

export default App;
