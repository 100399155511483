import React from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import Layout from '../Shared/Layout';

function Login() {
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await authService.login();
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleSignUp = async () => {
    try {
      await authService.register();
      navigate('/');
    } catch (error) {
      console.error('Sign up failed:', error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <Layout activeScreen="login">
      <div className="flex-grow flex flex-col items-center justify-center w-full max-w-2xl mx-auto px-4 h-full">
        <h2 className="text-white text-2xl mb-8">get chatting</h2>
        <div className="w-full max-w-xs flex flex-col items-center">
          <button
            onClick={handleSignUp}
            className="w-full h-14 rounded-full bg-white text-blue-500 font-semibold flex items-center justify-center shadow-lg transition-colors duration-200 hover:bg-gray-50 mb-4"
          >
            sign up
          </button>
          <button
            onClick={handleLogin}
            className="text-white hover:text-gray-200 transition-colors duration-200"
          >
            log in
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default Login;