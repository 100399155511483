import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ConfirmButtons = ({ state, handleConfirm, handleCancel, isTranscribing }) => {
    const buttonVariants = {
      hidden: { scale: 0.8, opacity: 0 },
      visible: { 
        scale: 1, 
        opacity: 1, 
        transition: { delay: 0.1, duration: 0.2 } 
      },
      exit: { 
        scale: 0.8, 
        opacity: 0, 
        transition: { duration: 0.2 } 
      }
    };
  
    return (
      <AnimatePresence>
        {state === 'confirming' && (
          <motion.div
            key="confirm"
            className="flex flex-col items-center justify-center h-full space-y-4"
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <motion.button
              className={`w-48 h-14 rounded-full bg-white text-blue-500 font-semibold flex items-center justify-center shadow-lg transition-colors duration-200 ${isTranscribing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
              variants={buttonVariants}
              whileTap={{ scale: 0.95 }}
              onClick={handleConfirm}
              disabled={isTranscribing}
            >
              {isTranscribing ? 'transcribing...' : 'create post'}
            </motion.button>
            <motion.button
              className={`text-white transition-colors duration-200 ${isTranscribing ? 'opacity-50 cursor-not-allowed' : 'hover:text-gray-200'}`}
              variants={buttonVariants}
              whileTap={{ scale: 0.95 }}
              onClick={handleCancel}
              disabled={isTranscribing}
            >
              cancel
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

export default ConfirmButtons;
