import React, { memo } from 'react';

const ProfileTextArea = memo(({ value, onChange }) => {
  return (
    <textarea
      value={value || ''}
      onChange={onChange}
      placeholder="eg:
- use UK english
- no questions at the end
- no emojis"
      className="w-full h-48 p-4 mb-4 bg-blue-500 text-white rounded-lg resize-none placeholder-blue-200"
    />
  );
});

export default ProfileTextArea;
