import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const PostCarousel = ({ onPrevious, onNext }) => {
  return (
    <div className="absolute left-0 right-0 top-1/2 -translate-y-1/2 flex justify-between pointer-events-none px-2 sm:px-4 md:px-6 lg:px-0 lg:max-w-2xl lg:mx-auto">
      <button 
        className="p-2 rounded-full text-white pointer-events-auto"
        onClick={onPrevious}
      >
        <ChevronLeft size={32} />
      </button>
      <button 
        className="p-2 rounded-full text-white pointer-events-auto"
        onClick={onNext}
      >
        <ChevronRight size={32} />
      </button>
    </div>
  );
};

export default PostCarousel;