import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../Shared/Layout';
import PostCard from './PostCard';
import { motion } from 'framer-motion';
import { fetchPostsAsync } from '../../redux/slices/appSlice';

const EmptyStateMessage = () => (
  <motion.div 
    className="text-center text-white mt-20"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <p className="text-2xl mb-4">it's a bit empty in here...</p>
    <p className="text-lg">
      hit that shiny record button in the bottom right to get this party started!
    </p>
  </motion.div>
);

const PostFeed = () => {
  const dispatch = useDispatch();
  const { posts, postsLoading, postsError } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(fetchPostsAsync());
  }, [dispatch]);

  if (postsLoading) {
    return (
      <Layout activeScreen="feed">
        <div className="w-full h-full flex flex-col items-center justify-center p-4 md:p-8">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="text-white text-xl text-center"
          >
            loading feed...
          </motion.div>
        </div>
      </Layout>
    );
  }

  if (postsError) {
    return (
      <Layout activeScreen="feed">
        <div className="w-full h-full flex flex-col items-center justify-center p-4 md:p-8">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="text-white text-xl text-center"
          >
            {postsError}
          </motion.div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout activeScreen="feed">
      <div className="w-full max-w-2xl mx-auto px-2 sm:px-4 md:px-8 mt-8 pb-20">
        {posts && posts.length > 0 ? (
          <>
            {posts.map(post => (
              <PostCard key={post.id} post={post} />
            ))}
          </>
        ) : (
          <EmptyStateMessage />
        )}
      </div>
    </Layout>
  );
};

export default PostFeed;