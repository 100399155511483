export const LOADING_MESSAGES = [
    "decoding your ramble...",
    "we're really making a post on this...",
    "injecting buzzwords for maximum impact...",
    "generating fake anecdotes...",
    "finding stock photos of people pointing at whiteboards...",
    "adding #blessed to everything...",
    "removing all traces of originality...",
    "adding unnecessary jargon...",
    "inserting vague inspirational quote...",
    "increasing the cringe factor...",
    "adding 'agree?' at the end...",
    "inserting random emojis...",
    "inserting meaningless statistics...",
    "adding excessive hashtags...",
    "removing 'like' and 'you know' (all 372 of them)...",
    // ... add more as needed
  ];

export const MOCK_POSTS = [
    {
        id: 1,
        content: "🚀 Reflecting on an Incredible Journey: 10 Years in Tech 🖥️\n\nAs I celebrate a decade in the tech industry today, I can't help but marvel at the incredible journey it's been. From my first lines of code to leading transformative projects, every step has been a learning experience.\n\n🔑 Key Milestones:\n1. 2013: Landed my first dev job at a startup\n2. 2015: Led my first team project - still proud of that MVP!\n3. 2017: Spoke at my first tech conference (terrifying but exhilarating)\n4. 2019: Launched a product that reached 1 million users\n5. 2021: Started mentoring young developers\n6. 2023: Here we are - leading innovation at scale!\n\n💡 Lessons Learned:\n- Embrace continuous learning. The tech landscape evolves rapidly.\n- Collaboration is key. The best ideas often emerge from diverse teams.\n- Fail fast, learn faster. Every 'failure' is a stepping stone to success.\n- User-centric design always wins. Technology should solve real problems.\n- Work-life balance isn't a myth. Burnout is real - take care of yourself!\n\n🙏 Gratitude:\nA massive thank you to all the mentors, colleagues, and teams I've had the privilege to work with. Your support and insights have been invaluable.\n\n🔮 Looking Ahead:\nExcited for the next decade! With emerging technologies like AI, quantum computing, and AR/VR, the possibilities are endless. My goal? To continue pushing boundaries, solving complex problems, and hopefully inspiring the next generation of tech innovators.\n\n💬 I'd love to hear about your tech journey! What's been your biggest learning? Any advice for those just starting?\n\nLet's connect and continue growing together in this amazing field!\n\n#TechAnniversary #CareerMilestone #TechIndustry #Innovation #PersonalGrowth",
        date: "16/05/23 09:00",
        versions: 5
    },
    {
        id: 2,
        content: "Just attended an incredible AI conference! 🤖✨\n\nMind = blown. 🤯\n\nKey takeaways:\n1. AI is evolving faster than we think\n2. Ethical considerations are more important than ever\n3. The future is collaborative: human + AI\n\nExcited to apply these insights to our projects. Stay tuned for some game-changing innovations!\n\n#AI #FutureTech #Innovation",
        date: "15/05/23 14:30",
        versions: 3
    },
    {
        id: 3,
        content: "What a day! 💪😃\n\nJust had the most amazing team building experience.\n\nHighlights:\n- Escape room challenge (we escaped with 2 minutes to spare!)\n- Improv workshop (who knew we had so many comedians?)\n- Strategy brainstorming session (the ideas! 💡)\n\nProud of this creative, resilient, and downright awesome team.\n\nHere's to many more successful collaborations!\n\n#TeamBuilding #CompanyCulture",
        date: "13/05/23 18:45",
        versions: 3
    },
    {
        id: 4,
        content: "Leadership lesson of the day:\n\nListen more than you speak.\n\nYou have two ears and one mouth for a reason.\n\nWhen you truly listen, you:\n1. Gain new perspectives\n2. Make better decisions\n3. Build stronger relationships\n\nChallenge: In your next meeting, focus on listening. You might be surprised by what you learn.\n\n#Leadership #Communication #PersonalGrowth",
        date: "12/05/23 11:20",
        versions: 2
    },
    {
        id: 5,
        content: "Excited to share that I'll be speaking at the upcoming Tech Innovation Summit! 🎤\n\nTopic: \"The Role of AI in Shaping Sustainable Business Practices\"\n\nDate: June 15-16, 2023\nVenue: San Francisco Convention Center\n\nIf you're attending, let's connect! Can't wait to exchange ideas and insights with fellow tech enthusiasts.\n\n#TechSummit #AI #Sustainability",
        date: "11/05/23 15:50",
        versions: 3
    },
    {
        id: 6,
        content: "📚 Just finished reading \"The Innovator's Dilemma\" by Clayton Christensen.\n\nA must-read for anyone in tech or business.\n\nKey insight: Success can be your biggest obstacle to future success.\n\nChristensen's exploration of disruptive innovation is more relevant now than ever.\n\nWhat books have shaped your business thinking?\n\n#BookRecommendation #Innovation #BusinessStrategy",
        date: "10/05/23 20:05",
        versions: 2
    }
    ];