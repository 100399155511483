import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../Shared/Layout';
import LoadingMessage from './LoadingMessage';
import { LOADING_MESSAGES } from '../../utils/constants';
import { createPost } from '../../services/api';
import { useToast } from '../../toast/ToastContext';
import { useDispatch } from 'react-redux';
import { setTranscriptionId } from '../../redux/slices/appSlice';

const GeneratePost = () => {
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const isGeneratingRef = useRef(false);
  const hasCalledApiRef = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const transcriptionId = location.state?.transcriptionId;

  const generatePost = useCallback(async () => {
    if (isGeneratingRef.current || hasCalledApiRef.current) {
      return;
    }

    isGeneratingRef.current = true;
    hasCalledApiRef.current = true;

    if (!transcriptionId) {
      addToast('Error creating post. Please try recording again.', 'error');
      navigate('/');
      return;
    }

    try {
      await createPost(transcriptionId);
      dispatch(setTranscriptionId(null));
      navigate('/');
    } catch (error) {
      console.error('Error creating post:', error.message);
      addToast('Error with post creation. Please try again.', 'error');
      navigate('/');
    } finally {
      isGeneratingRef.current = false;
    }
  }, [transcriptionId, navigate, addToast, dispatch]);

  useEffect(() => {
    const loadingInterval = setInterval(() => {
      setLoadingMessageIndex(prevIndex => {
        const newIndex = Math.floor(Math.random() * LOADING_MESSAGES.length);
        return newIndex !== prevIndex ? newIndex : (newIndex + 1) % LOADING_MESSAGES.length;
      });
    }, 2000);

    generatePost();

    return () => {
      clearInterval(loadingInterval);
    };
  }, [generatePost]);

  return (
    <Layout activeScreen="generate">
      <motion.div 
        className="w-full h-full flex flex-col items-center justify-center p-4 md:p-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <LoadingMessage message={LOADING_MESSAGES[loadingMessageIndex]} />
      </motion.div>
    </Layout>
  );
};

export default GeneratePost;
