import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPosts, fetchPostById, deletePost } from '../../services/api';

export const fetchPostsAsync = createAsyncThunk(
  'app/fetchPosts',
  async () => {
    const response = await fetchPosts();
    return response.data;
  }
);

export const fetchPostByIdAsync = createAsyncThunk(
  'app/fetchPostById',
  async (id) => {
    const response = await fetchPostById(id);
    return response.data;
  }
);

export const deletePostAsync = createAsyncThunk(
  'app/deletePost',
  async (id) => {
    await deletePost(id);
    return id;
  }
);

const initialState = {
  isRecording: false,
  recordingDuration: 0,
  showConfirmButtons: false,
  isTranscribing: false, // Changed from isAnalyzingTone
  transcriptionId: null,
  posts: [],
  currentPost: null,
  postsLoading: false,
  postsError: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsRecording: (state, action) => {
      state.isRecording = action.payload;
    },
    setRecordingDuration: (state, action) => {
      state.recordingDuration = action.payload;
    },
    resetRecordingDuration: (state) => {
      state.recordingDuration = 0;
    },
    setShowConfirmButtons: (state, action) => {
      state.showConfirmButtons = action.payload;
    },
    setIsTranscribing: (state, action) => {
      state.isTranscribing = action.payload;
    }, // Changed from setIsAnalyzingTone
    setTranscriptionId: (state, action) => {
      state.transcriptionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPostsAsync.pending, (state) => {
        state.postsLoading = true;
        state.postsError = null;
      })
      .addCase(fetchPostsAsync.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.posts = action.payload.map(post => ({
          ...post,
          versions: Array.isArray(post.versions) ? post.versions.filter(v => v !== '') : 
                    (post.versions ? [post.versions] : [])
        }));
      })
      .addCase(fetchPostsAsync.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = action.error.message;
      })
      .addCase(fetchPostByIdAsync.pending, (state) => {
        state.postsLoading = true;
        state.postsError = null;
      })
      .addCase(fetchPostByIdAsync.fulfilled, (state, action) => {
        state.postsLoading = false;
        state.currentPost = action.payload;
      })
      .addCase(fetchPostByIdAsync.rejected, (state, action) => {
        state.postsLoading = false;
        state.postsError = action.error.message;
      })
      .addCase(deletePostAsync.fulfilled, (state, action) => {
        state.posts = state.posts.filter(post => post.id !== action.payload);
        state.currentPost = null;
      });
  },
});

export const { 
  setIsRecording, 
  setRecordingDuration, 
  resetRecordingDuration, 
  setShowConfirmButtons, 
  setIsTranscribing, 
  setTranscriptionId 
} = appSlice.actions;

export default appSlice.reducer;