import { useState, useCallback, useRef } from 'react';

const useAudioRecording = () => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  // Added new ref for stream
  const stream = useRef(null);

  const startRecording = useCallback(async () => {
    try {
      stream.current = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          channelCount: 1,
          sampleRate: 16000
        }
      });

      const options = {
        mimeType: 'audio/webm',
        audioBitsPerSecond: 128000
      };

      // Ensure we're using audio/webm
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.warn('audio/webm not supported, trying alternatives');
        const mimeTypes = [
          'audio/ogg',
          'audio/wav',
          'audio/mp4'
        ];
        
        for (const type of mimeTypes) {
          if (MediaRecorder.isTypeSupported(type)) {
            options.mimeType = type;
            break;
          }
        }
      }

      if (process.env.NODE_ENV !== 'production') {
        console.log('Recording with MIME type:', options.mimeType);
      }

      mediaRecorder.current = new MediaRecorder(stream.current, options);

      mediaRecorder.current.ondataavailable = (e) => {
        audioChunks.current.push(e.data);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Recording failed to start');
    }
  }, []);

  const stopRecording = useCallback(() => {
    return new Promise((resolve) => {
      if (mediaRecorder.current && isRecording) {
        mediaRecorder.current.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, { 
            type: 'audio/webm;codecs=opus'
          });
          audioChunks.current = [];
          
          if (process.env.NODE_ENV === 'production') {
            console.log('Audio recorded successfully');
          } else {
            console.log('Created audio blob:', {
              type: audioBlob.type,
              size: audioBlob.size
            });
          }
          
          // release the microphone
          if (stream.current) {
            stream.current.getTracks().forEach(track => track.stop());
            stream.current = null;
          }

          setIsRecording(false);
          resolve(audioBlob);
        };
        mediaRecorder.current.stop();
      } else {
        resolve(null);
      }
    });
  }, [isRecording]);


  return {
    isRecording,
    startRecording,
    stopRecording,

  };
};

export default useAudioRecording;
