import React, { useState, useEffect } from 'react';
import { Copy, Trash } from 'lucide-react';
import { motion } from 'framer-motion';
import { useToast } from '../../toast/ToastContext';

const ActionButtons = ({ onCopy, onDelete }) => {
  const [deleteState, setDeleteState] = useState('initial');
  const { addToast } = useToast();

  useEffect(() => {
    let timer;
    if (deleteState === 'confirm') {
      timer = setTimeout(() => setDeleteState('initial'), 5000);
    }
    return () => clearTimeout(timer);
  }, [deleteState]);

  const handleDeleteClick = () => {
    if (deleteState === 'initial') {
      setDeleteState('confirm');
      addToast('this will delete all versions. click again to confirm.', 'info', 5000);
    } else if (deleteState === 'confirm') {
      onDelete();
    }
  };

  return (
    <div className="fixed bottom-6 left-0 right-0 flex justify-center items-center space-x-4 px-4">
      <motion.button 
        className="w-16 h-16 rounded-full flex items-center justify-center focus:outline-none bg-white shadow-lg"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onCopy}
      >
        <div className="w-12 h-12 rounded-full flex items-center justify-center bg-blue-100">
          <Copy size={24} className="text-blue-500" />
        </div>
      </motion.button>
      <motion.button 
        className={`p-3 rounded-full flex items-center justify-center focus:outline-none ${
          deleteState === 'confirm' ? 'bg-white shadow-lg' : 'bg-white bg-opacity-20'
        }`}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleDeleteClick}
      >
        {deleteState === 'confirm' ? (
          <Trash size={24} className="text-gray-500" />
        ) : (
          <Trash size={24} className="text-white" />
        )}
      </motion.button>
    </div>
  );
};

export default ActionButtons;
