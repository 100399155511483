import React from 'react';
import { Link } from 'react-router-dom';
import { User } from 'lucide-react';

const ProfileIcon = () => {
  return (
    <Link to="/profile" className="absolute top-4 right-4 text-white">
      <User size={24} />
    </Link>
  );
};

export default ProfileIcon;