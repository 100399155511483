import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../Shared/Layout';
import ProfileTextArea from './ProfileTextArea';
import { getUserProfile, updateUserProfile } from '../../services/api';
import { useToast } from '../../toast/ToastContext';
import authService from '../../services/authService';

const ProfilePage = () => {
  const [userInfo, setUserInfo] = useState('');
  const [userName, setUserName] = useState('');
  const { addToast } = useToast();
  const location = useLocation();

  const fetchUserProfile = useCallback(async () => {
    try {
      const response = await getUserProfile();
      setUserInfo(response.data.profile_text || '');
      setUserName(response.data.name || '');
    } catch (error) {
      console.error('Failed to fetch user profile');
      addToast('Failed to load profile. Please try again.', 'error');
    }
  }, [addToast]);

  useEffect(() => {
    fetchUserProfile();
  }, [location.pathname, fetchUserProfile]);

  const handleSaveProfile = async () => {
    try {
      await updateUserProfile(userInfo);
      addToast('Guidelines saved successfully', 'success');
      await fetchUserProfile();
    } catch (error) {
      // Remove sensitive error logging
      console.error('Failed to save user profile');
      addToast('Failed to save guidelines. Please try again.', 'error');
    }
  };

  return (
    <Layout activeScreen="profile">
      <div className="flex-grow flex flex-col items-center justify-center w-full max-w-2xl mx-auto px-4 h-full">
        <h2 className="text-white text-3xl font-semibold mb-3">post preferences</h2>
        
        <p className="text-white text-lg text-center mb-6">
          got any post preferences {userName}? drop them below.
        </p>
        
        <ProfileTextArea
          value={userInfo}
          onChange={(e) => setUserInfo(e.target.value)}
        />
        
        <button 
          onClick={handleSaveProfile}
          className="w-48 h-14 rounded-full bg-white text-blue-500 font-semibold flex items-center justify-center shadow-lg transition-colors duration-200 hover:bg-gray-50 mb-4"
        >
          save
        </button>

        <button 
          onClick={() => authService.logout()}
          className="text-white hover:text-gray-200 transition-colors duration-200 fixed bottom-8"
        >
          logout
        </button>
      </div>
    </Layout>
  );
};

export default ProfilePage;
