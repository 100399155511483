import axios from 'axios';

// Ensure the API URL is defined
if (!process.env.REACT_APP_API_URL) {
  throw new Error('REACT_APP_API_URL is not defined in the environment');
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true  // Kept this option from the original file
});

// Add token to requests
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  console.error('Request interceptor error:', error);
  return Promise.reject(error);
});

// Remove the response interceptor for logging
api.interceptors.response.use(response => {
  return response;
}, error => {
  // Log only non-sensitive information
  console.error('API Error:', error.message);
  return Promise.reject(error);
});

// Simplified API functions
export const fetchPosts = () => api.get('/posts');
export const createPost = (transcriptionId) => api.post('/posts', { transcriptionId });
export const getUserProfile = () => api.get('/users/profile');
export const updateUserProfile = (profileText) => 
  api.put('/users/profile', { profile_text: profileText });

export const getPostStatus = async (postId) => {
  const response = await api.get(`/posts/${postId}/status`);
  return response.data;
};

export const fetchPostById = (id) => api.get(`/posts/${id}`);
export const updatePost = (id, postData) => api.put(`/posts/${id}`, postData);
export const deletePost = (id) => api.delete(`/posts/${id}`);

// Function for audio transcription
export const transcribeAudio = (audioBlob) => {
  const formData = new FormData();
  formData.append('audio', audioBlob, 'recording.webm');
  
  return api.post('/transcription/transcribe', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).catch(error => {
    console.error('Transcription error:', error.message);
    throw error;
  });
};

// Function to delete temporary transcription
export const deleteTemporaryTranscription = (transcriptionId) => {
  return api.delete(`/transcription/${transcriptionId}`);
};

// Export the configured axios instance
export default api;
