import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import RecordFAB from './RecordFAB';
import ProfileIcon from '../ProfilePage/ProfileIcon';

const Layout = ({ children, isScrollable = true }) => {
  const location = useLocation();
  const isRecording = useSelector((state) => state.app.isRecording);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isSmallScreen = windowHeight < 400;

  const backgroundStyle = {
    background: isRecording
      ? 'linear-gradient(to bottom, #FF6B6B, #FF8E8E)'
      : 'linear-gradient(to bottom, #60A5FA, #3B82F6)',
    height: isSmallScreen ? `${windowHeight}px` : '100vh',
  };

  const shouldShowProfileIcon = !['/record', '/generate', '/login'].includes(location.pathname) && 
                                !location.pathname.startsWith('/post/');

  return (
    <div 
      className={`flex flex-col ${isScrollable ? 'overflow-auto' : 'overflow-hidden'}`}
      style={backgroundStyle}
    >
      {shouldShowProfileIcon && <ProfileIcon />}
      
      <header className="flex justify-center items-center p-4">
        <Header />
      </header>
      
      <main className={`flex-grow flex flex-col ${isScrollable ? 'overflow-auto' : 'overflow-hidden'}`}>
        <div className={`flex-grow flex items-start justify-center w-full max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 ${isSmallScreen ? 'overflow-auto' : ''}`}>
          {children}
        </div>
      </main>
      
      <RecordFAB />
    </div>
  );
};

export default Layout;