import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

function AuthCallback({ onLogin }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleCallback = async () => {
      const token = searchParams.get('token');
      
      if (token) {
        localStorage.setItem('token', token);
        console.log('Authentication successful');
        onLogin();
        navigate('/');
      } else {
        console.warn('Authentication failed: No token received');
        navigate('/login');
      }
    };

    handleCallback();
  }, [navigate, searchParams, onLogin]);

  return <div>Authenticating...</div>;
}

export default AuthCallback;
