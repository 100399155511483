import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../Shared/Layout';
import ConfirmButtons from './ConfirmButtons';
import TimerDisplay from './TimerDisplay';
import useAudioRecording from '../../hooks/useAudioRecording';
import { 
  setRecordingDuration, 
  resetRecordingDuration, 
  setShowConfirmButtons, 
  setTranscriptionId
} from '../../redux/slices/appSlice';
import { useToast } from '../../toast/ToastContext';
import { deleteTemporaryTranscription } from '../../services/api';

const RecordScreen = () => {
  const { 
    isRecording, 
    recordingDuration, 
    showConfirmButtons, 
    isTranscribing, 
    transcriptionId 
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isRecording: isAudioRecording, startRecording } = useAudioRecording();
  const { addToast } = useToast();

  useEffect(() => {
    if (isRecording && !isAudioRecording) {
      startRecording();
    }
  }, [isRecording, isAudioRecording, startRecording]);

  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        dispatch(setRecordingDuration(recordingDuration + 1));
      }, 1000);
    } else {
      dispatch(resetRecordingDuration());
    }
    return () => clearInterval(interval);
  }, [isRecording, recordingDuration, dispatch]);

  const handleConfirm = () => {
    dispatch(setShowConfirmButtons(false));
    navigate('/generate', { state: { transcriptionId } });
  };

  const handleCancel = () => {
    dispatch(setShowConfirmButtons(false));
    dispatch(setTranscriptionId(null));
    navigate('/');

    if (transcriptionId) {
      deleteTemporaryTranscription(transcriptionId)
        .catch(error => {
          console.error('Error deleting temporary transcription');
          addToast('An error occurred while processing your request', 'error');
        });
    }
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Layout>
      <div className="w-full h-full flex flex-col items-center justify-center">
        {isRecording ? (
          <TimerDisplay
            state="recording"
            recordingDuration={recordingDuration}
            formatDuration={formatDuration}
          />
        ) : isTranscribing ? (
          <div className="text-white text-xl">transcribing...</div>
        ) : showConfirmButtons ? (
          <ConfirmButtons
            state="confirming"
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
            isTranscribing={false}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default RecordScreen;
