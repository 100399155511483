import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const TimerDisplay = ({ state, recordingDuration, formatDuration }) => {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <AnimatePresence>
          {state === 'recording' && (
            <motion.div
              className="text-white text-6xl font-bold"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              {formatDuration(recordingDuration)}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

export default TimerDisplay;
