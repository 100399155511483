const AUTH_URL = `${process.env.REACT_APP_API_URL}/auth`;

const authService = {
  login: () => {
    console.log('Initiating login process');
    window.location.href = `${AUTH_URL}/login`;
  },

  register: () => {
    console.log('Initiating registration process');
    window.location.href = `${AUTH_URL}/register`;
  },

  logout: () => {
    console.log('Logging out user');
    localStorage.removeItem('token');
    window.location.href = `${AUTH_URL}/logout`;
  },

  handleCallback: (token) => {
    if (token) {
      console.log('Authentication callback successful');
      localStorage.setItem('token', token);
      return true;
    }
    console.warn('Authentication callback failed: No token received');
    return false;
  },

  isAuthenticated: () => {
    return !!localStorage.getItem('token');
  }
};

export default authService;
