import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const PostCard = ({ post }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/post/${post.id}`);
  };

  // Get the first version of the post content
  const content = post.versions && post.versions.length > 0 ? post.versions[0] : '';

  // Format the date and time
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  };

  // Calculate the number of versions
  const versionCount = Array.isArray(post.versions) ? post.versions.filter(v => v !== '').length : 0;

  return (
    <motion.div 
      className="bg-white rounded-lg p-4 mb-4 cursor-pointer"
      onClick={handleClick}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
    >
      <p className="text-gray-800 mb-3 line-clamp-4">
        {content}
      </p>
      <div className="flex justify-between items-center text-xs text-gray-400">
        <span>{formatDateTime(post.createdAt)}</span>
        <span>{versionCount} version{versionCount !== 1 ? 's' : ''}</span>
      </div>
    </motion.div>
  );
};

export default PostCard;
