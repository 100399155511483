import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const PostDetailCard = ({ post, currentVersionIndex, versionsCount, onSwipe }) => {
  const navigate = useNavigate();

  // Get the current version's content
  const currentVersion = Array.isArray(post.versions) ? post.versions[currentVersionIndex] : post.versions;

  // Split the content by line breaks
  const lines = currentVersion.split('\n');

  // Format the date and time
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <motion.div
      className="bg-white rounded-lg flex flex-col w-full h-[calc(100vh-4rem)] max-h-[550px] xs:h-mobile-card xs:max-h-none relative mb-6 xs:mb-3 overflow-hidden"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.x, velocity.x);
        if (swipe < -swipeConfidenceThreshold) {
          onSwipe('left');
        } else if (swipe > swipeConfidenceThreshold) {
          onSwipe('right');
        }
      }}
    >
      <button
        onClick={() => navigate('/')}
        className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors duration-200 z-10"
        aria-label="Close"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <div className="flex flex-col h-full pt-10">
        <div className="flex-grow overflow-y-auto p-4 xs:p-2">
          <div className="text-gray-800">
            {lines.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < lines.length - 1 && <br />}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="flex-shrink-0 flex justify-between items-center text-xs text-gray-400 p-2 bg-white">
          <span>{formatDateTime(post.createdAt)}</span>
          <span>
            Version {currentVersionIndex + 1} of {versionsCount}
          </span>
        </div>
      </div>
    </motion.div>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export default PostDetailCard;
