import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="font-poppins text-4xl font-extrabold text-white mt-4 mb-2 text-center">
      <Link to="/" className="font-extrabold">
        chatpost
      </Link>
    </header>
  );
};

export default Header;
